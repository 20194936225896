<template>
  <BaseModal
    name="confirm-affiliation"
    size="md"
    title="Termos de uso Afiliação Voomp"
    @hidden="reset"
    @shown="openModal"
  >
    <p class="informativo" v-if="!comentario">
      Antes de se afiliar, leia ações que não podem ser praticadas por quem se
      afilia a produtos no Voomp:
    </p>
    <ul class="termos" v-if="!comentario">
      <li>
        <span>01.</span>
        <p>
          SPAM. Não envie mensagens em massa por e-mail, Whatsapp ou outros
          canais;
        </p>
      </li>
      <li>
        <span>02.</span>
        <p>
          Não use a marca "Voomp" quando divulgar esse produto, deixe claro que
          ele pertence ao seus criadores;
        </p>
      </li>
      <li>
        <span>03.</span>
        <p>
          Não ofereça ou prometa benefícios diferentes do que o produto oferece,
          ou resultados garantidos.
        </p>
      </li>
    </ul>

    <div v-if="comentario" data-anima="top">
      <b-form-group
        label="Digite uma mensagem para o Produtor"
        label-for="observation"
      >
        <b-form-textarea
          id="observation"
          placeholder="Digite uma mensagem para o Produtor"
          name="observation"
          v-model="observation"
          v-validate="'required'"
        ></b-form-textarea>
        <b-form-invalid-feedback :state="!errors.has('observation')">
          Por favor, escreva a mensagem.
        </b-form-invalid-feedback>
      </b-form-group>
    </div>
    <template v-slot:footer="{}">
      <div class="botoes">
        <BaseButton
          variant="info"
          class="criar-conta"
          @click="openRegister"
          v-if="!logado"
        >
          Criar conta
        </BaseButton>
        <BaseButton
          variant="secondary_outline"
          class="entrar-conta"
          @click="openLogin"
          v-if="!logado"
        >
          Já tenho conta
        </BaseButton>
        <BaseButton
          variant="secondary_outline"
          class="entrar-conta"
          :disabled="loading"
          @click="validateAccept"
          v-if="logado"
        >
          Sim, quero me afiliar agora
        </BaseButton>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import AffiliateService from "@/services/resources/AffiliateService";
const serviceAffiliate = AffiliateService.build();

import Cookies from "js-cookie";
export default {
  name: "ConfirmAffiliation",
  data() {
    return {
      aprovacao_component: 0,
      loading: false,
      comentario: false,
      observation: "",
      logado: Cookies.get("access_token"),
    };
  },
  props: {
    hash_afiliado: {
      type: String,
      default: "",
    },
    product_id: {
      type: Number,
      default: -1,
    },
    product_name: {
      type: String,
      default: "",
    },
    aprovacao: {
      type: Number,
      default: -1,
    },
    manager: {
      type: String,
      default: "",
    },
  },
  computed: {
    userId() {
      return this.$store.getters.getProfile.id;
    },
  },
  methods: {
    reset() {
      this.comentario = false;
      this.observation = "";
      this.aprovacao_component = 0;
    },
    openModal() {
      console.log(this.aprovacao);
      this.aprovacao_component = this.aprovacao;
    },
    openRegister() {
      const query = this.$route.query;
      query.hash_a = this.hash_afiliado;
      this.$router.push({
        name: "Seller-Register",
        query: query,
      });
    },
    openLogin() {
      this.$router.push(
        `/?hash_a=${this.hash_afiliado}&p_id=${this.product_id}&manager=${this.manager}`
      );
    },
    validateAccept() {
      if (this.aprovacao_component) {
        this.comentario = true;
        this.aprovacao_component = 0;
      } else {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.acceptInvite();
          }
        });
      }
    },
    acceptInvite() {
      this.loading = true;
      let data = {
        hash: this.hash_afiliado,
        url: "accept-invite",
      };

      if (this.manager) {
        data.manager = this.manager;
      }
      if (this.aprovacao) {
        data.observation = this.observation;
      }

      serviceAffiliate
        .search(data)
        .then(() => {
          // validação
          if (!this.aprovacao) {
            this.getAffiliation(this.product_id);
          } else {
            this.$emit("close");
            this.$bvToast.toast(
              "Afiliação solicitada. Aguarde a aprovação para começar a vender.",
              {
                title: "Afiliação",
                variant: "info",
                autoHideDelay: 9000,
                noAutoHide: true,
                appendToast: true,
              }
            );
          }
        })
        .catch((err) => {
          console.log(err);
          const r = err.response;
          if (r) {
            this.$bvToast.toast(
              "Convite inválido ou já em uso. Solicite um novo convite ao produtor",
              {
                title: "Afiliação",
                variant: "danger",
                autoHideDelay: 5000,
                appendToast: true,
              }
            );
          }
        })
        .finally(() => {
          this.loading = false;
          this.$bvModal.hide("confirm-affiliation");
        });
    },
    getAffiliation(id_product) {
      let data = {
        id: `${this.userId}/${id_product}`,
      };

      serviceAffiliate
        .read(data)
        .then((resp) => {
          this.$router.push(
            `/links/?affiliation_id=${resp.id}&p_name=${this.product_name}&comingAffiliate=true`
          );
        })
        .catch((err) => {
          this.$router.push(`/links/?comingAffiliate=true`);
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
p {
  color: #81858e;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.5;
  margin: 0;
}
.informativo {
  color: #333333;
  font-size: 15px;
  font-weight: 600;
  /* max-width: 80%; */
}
.termos {
  margin: 30px 0 20px 0;
  /* max-width: 80%; */
}
.termos li {
  display: flex;
  align-items: flex-start;
  gap: 15px;
}
.termos li + li {
  margin-top: 20px;
}
.termos li span {
  font-weight: 600;
  font-size: 15px;
  color: #2a63ab;
}
.botoes {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  width: 100%;
}

.criar-conta,
.entrar-conta {
  flex: 1;
}
.criar-conta {
  color: #2a63ab;
  background: rgba(33, 51, 210, 0.1);
  border: none;
}
#observation {
  resize: none !important;
  height: 15vh !important;
}
</style>
